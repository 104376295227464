import {
  CheckIfCustomerExistsRequest,
  CheckIfCustomerExistsResponse, CheckSignedInRequest, CheckSignedInResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignInRequest,
  SignInResponse,
  SignOutRequest,
  SignUpRequest,
  SignUpResponse
} from '@winestyle/api-client/src/ts/api/customer/v1/customer_identity_api_pb'

import type { CustomerIdentityAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/customer_identity_api_grpc_web_pb'
import type { ReqTypeWithResponseCases } from '@/modules/nuxt-api/models/type-helpers/reqTypeWithResponseCases'

export const customerAuthApi = (customerAPIClient: CustomerIdentityAPIPromiseClient) => {
  async function signIn (login: string, password: string, captchaCode?: string) {
    const request: ReqTypeWithResponseCases<SignInRequest> = new SignInRequest()

    request.setLogin(login)
    request.setPassword(password)
    request.responseCases = SignInResponse.ResponseCase
    captchaCode && request.setCaptchaCode(captchaCode)

    const res = await customerAPIClient.signIn(request)
    return res.getData()?.toObject() || []
  }

  async function signUp (email: string, phone: string, password: string, name: string, captchaCode?: string) {
    const request: ReqTypeWithResponseCases<SignUpRequest> = new SignUpRequest()

    request.setEmail(email)
    request.setPhone(phone)
    request.setPassword(password)
    request.setName(name)
    captchaCode && request.setCaptchaCode(captchaCode)

    request.responseCases = SignUpResponse.ResponseCase
    request.checkValidation = true

    return await customerAPIClient.signUp(request)
  }

  async function checkIfExists (login: string) {
    const request: ReqTypeWithResponseCases<CheckIfCustomerExistsRequest> = new CheckIfCustomerExistsRequest()

    request.setLogin(login)

    request.responseCases = CheckIfCustomerExistsResponse.ResponseCase

    const res = await customerAPIClient.checkIfCustomerExists(request)
    return res.getData()?.getResult?.()
  }
  async function checkSignedIn () {
    const request: ReqTypeWithResponseCases<CheckSignedInRequest> = new CheckSignedInRequest()
    request.responseCases = CheckSignedInResponse.ResponseCase

    const res = await customerAPIClient.checkSignedIn(request)
    return res.getData()?.getResult?.()
  }

  async function resetPassword (login: string, captchaCode?: string) {
    const request: ReqTypeWithResponseCases<ResetPasswordRequest> = new ResetPasswordRequest()

    request.setLogin(login)
    captchaCode && request.setCaptchaCode(captchaCode)
    request.responseCases = ResetPasswordResponse.ResponseCase

    return await customerAPIClient.resetPassword(request)
  }

  async function signOut () {
    const request = new SignOutRequest()
    const res = await customerAPIClient.signOut(request)

    return res.getServerError()
  }

  return {
    signIn, signUp, signOut, checkIfExists, resetPassword, checkSignedIn
  }
}
