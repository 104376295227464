import { CatalogAPIPromiseClient } from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_api_grpc_web_pb.js'
import { ProductAPIPromiseClient } from '@winestyle/api-client/src/ts/api/catalog/v1/product_api_grpc_web_pb.js'
import { ProductCollectionAPIPromiseClient } from '@winestyle/api-client/src/ts/api/catalog/v1/product_collections_api_grpc_web_pb'
import { ReviewsAPIPromiseClient } from '@winestyle/api-client/src/ts/api/reviews/v1/reviews_api_grpc_web_pb'
import { SearchAPIPromiseClient } from '@winestyle/api-client/src/ts/api/search/v1/search_api_grpc_web_pb.js'
import { ShopAPIPromiseClient } from '@winestyle/api-client/src/ts/api/shop/v1/shop_api_grpc_web_pb.js'
import { BannerAPIPromiseClient } from '@winestyle/api-client/src/ts/api/marketing/v1/banner_api_grpc_web_pb.js'
import { MarketingAPIPromiseClient } from '@winestyle/api-client/src/ts/api/marketing/v1/marketing_api_grpc_web_pb.js'
import { CartAPIPromiseClient } from '@winestyle/api-client/src/ts/api/sales/v1/cart_api_grpc_web_pb'
import { RegionAPIPromiseClient } from '@winestyle/api-client/src/ts/api/region/v1/region_api_grpc_web_pb'
import { IdentityAPIPromiseClient } from '@winestyle/api-client/src/ts/internal/identity/v1/identity_api_grpc_web_pb'
import { CustomerIdentityAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/customer_identity_api_grpc_web_pb'
import { CustomerProfileAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/customer_profile_api_grpc_web_pb'
import { OrdersAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/orders_api_grpc_web_pb'
import { WishListAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/wishlist_api_grpc_web_pb'
import { CustomerRequestAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/customer_request_api_grpc_web_pb'

import { catalogAPI } from './catalog'
import { metaAPI } from './meta'
import { productAPI } from './product'
import { productCollectionAPI } from './product-collection'
import { reviewsAPI } from './reviews'

import { searchAPI } from './search'
import { shopAPI } from './shop'
import { marketingAPI } from './marketing'

import { bannerAPI } from './banner'
import { cartAPI } from './cart'
import { ordersApi } from './orders'

import { regionAPI } from './region'
import { identityAuthApi } from './auth/identity'
import { customerAuthApi } from './auth/customer'
import { customerProfileApi } from './auth/profile'

import { wishListAPI } from './wishlist'
import { customerSupportRequestApi } from './customer'

import type { MetaAPI } from './meta'
import type { ProductCollectionAPI } from './product-collection'
import type { ReviewsAPI } from './reviews'
import type { SearchAPI } from './search'
import type { ShopAPI } from './shop'
import type { MarketingAPI } from './marketing'
import type { CartAPI } from './cart'
import type { RegionAPI } from './region'
import type { IdentityAuthApi } from './auth/identity'
import type { WishListAPI } from './wishlist'
import type { CustomerSupportRequestAPI } from './customer'

import type { UnaryInterceptor } from 'grpc-web'
import type { Message } from 'google-protobuf'

export type ApiType = {
  catalogAPIClient: CatalogAPIPromiseClient
  productAPIClient: ProductAPIPromiseClient
  productCollectionAPIClient: ProductCollectionAPIPromiseClient
  reviewsAPIClient: ReviewsAPIPromiseClient
  searchAPIClient: SearchAPIPromiseClient
  cartAPIClient: CartAPIPromiseClient
  identityAPIClient: IdentityAPIPromiseClient
  customerAPIClient: CustomerIdentityAPIPromiseClient
  customerProfileAPIClient: CustomerProfileAPIPromiseClient
  ordersAPIClient: OrdersAPIPromiseClient
  wishListAPIClient: WishListAPIPromiseClient
  supportCustomerAPIClient: CustomerRequestAPIPromiseClient
  region: () => RegionAPI
  catalog: () => ReturnType<typeof catalogAPI>
  meta: () => MetaAPI
  product: () => ReturnType<typeof productAPI>
  reviews: () => ReviewsAPI
  search: () => SearchAPI
  shop: () => ShopAPI
  marketing: () => MarketingAPI
  banner: () => ReturnType<typeof bannerAPI>
  cart: () => CartAPI
  orders: () => ReturnType<typeof ordersApi>
  support: () => CustomerSupportRequestAPI
  productCollection: () => ProductCollectionAPI
  auth: {
    identity: () => IdentityAuthApi
    customer: () => ReturnType<typeof customerAuthApi>
    profile: () => ReturnType<typeof customerProfileApi>
  }
  wishlist: () => WishListAPI
}

export function createApiClients (API_URL: string, unaryInterceptors?: UnaryInterceptor<Message, Message>[]): ApiType {
  const options = {
    unaryInterceptors
  }

  const regionAPIClient = new RegionAPIPromiseClient(API_URL, null, options)
  const catalogAPIClient = new CatalogAPIPromiseClient(API_URL, null, options)
  const productAPIClient = new ProductAPIPromiseClient(API_URL, null, options)
  const reviewsAPIClient = new ReviewsAPIPromiseClient(API_URL, null, options)
  const searchAPIClient = new SearchAPIPromiseClient(API_URL, null, options)
  const shopAPIClient = new ShopAPIPromiseClient(API_URL, null, options)
  const marketingAPIClient = new MarketingAPIPromiseClient(API_URL, null, options)
  const bannerAPIClient = new BannerAPIPromiseClient(API_URL, null, options)
  const cartAPIClient = new CartAPIPromiseClient(API_URL, null, options)
  const identityAPIClient = new IdentityAPIPromiseClient(API_URL, null, options)
  const customerAPIClient = new CustomerIdentityAPIPromiseClient(API_URL, null, options)
  const customerProfileAPIClient = new CustomerProfileAPIPromiseClient(API_URL, null, options)
  const ordersAPIClient = new OrdersAPIPromiseClient(API_URL, null, options)
  const wishListAPIClient = new WishListAPIPromiseClient(API_URL, null, options)
  const supportCustomerAPIClient = new CustomerRequestAPIPromiseClient(API_URL, null, options)
  const productCollectionAPIClient = new ProductCollectionAPIPromiseClient(API_URL, null, options)

  return {
    catalogAPIClient,
    productAPIClient,
    productCollectionAPIClient,
    reviewsAPIClient,
    searchAPIClient,
    cartAPIClient,
    identityAPIClient,
    customerAPIClient,
    customerProfileAPIClient,
    ordersAPIClient,
    wishListAPIClient,
    supportCustomerAPIClient,
    region: () => regionAPI(regionAPIClient),
    catalog: () => catalogAPI(catalogAPIClient),
    meta: () => metaAPI(catalogAPIClient),
    product: () => productAPI(productAPIClient, catalogAPIClient),
    productCollection: () => productCollectionAPI(productCollectionAPIClient),
    reviews: () => reviewsAPI(reviewsAPIClient),
    search: () => searchAPI(searchAPIClient),
    shop: () => shopAPI(shopAPIClient),
    marketing: () => marketingAPI(marketingAPIClient),
    banner: () => bannerAPI(bannerAPIClient),
    cart: () => cartAPI(cartAPIClient),
    support: () => customerSupportRequestApi(supportCustomerAPIClient),
    orders: () => ordersApi(ordersAPIClient),
    auth: {
      identity: () => identityAuthApi(identityAPIClient),
      customer: () => customerAuthApi(customerAPIClient),
      profile: () => customerProfileApi(customerProfileAPIClient)
    },
    wishlist: () => wishListAPI(wishListAPIClient)
  }
}
