import type { IdentityAPIPromiseClient } from '@winestyle/api-client/src/ts/internal/identity/v1/identity_api_grpc_web_pb'
import type { ReqTypeWithResponseCases } from '@/modules/nuxt-api/models/type-helpers/reqTypeWithResponseCases'

import { IdentifyRequest, IdentifyResponse } from '@winestyle/api-client/src/ts/internal/identity/v1/identity_api_pb'

export type IdentityAuthApi = {
  getToken: () => Promise<string | undefined>
}

export const identityAuthApi = (identityAPIClient: IdentityAPIPromiseClient): IdentityAuthApi => {
  /**
   * Generate new token
   */
  const getToken = async (): Promise<string | undefined> => {
    const request: ReqTypeWithResponseCases<IdentifyRequest> = new IdentifyRequest()
    request.responseCases = IdentifyResponse.ResponseCase
    const res = await identityAPIClient.identify(request)

    return res.getData()?.getUserToken()
  }

  return { getToken }
}
