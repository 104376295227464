import {
  GetBestReviewsRequest,
  GetReviewsForProductRequest,
  GetMetadataRequest,
  SendReviewRequest
} from '@winestyle/api-client/src/ts/api/reviews/v1/reviews_api_pb'
import { CursorPaginator } from '@winestyle/api-client/src/ts/api/shared/v1/common_pb.js'

import type { CursorPagination } from '@winestyle/api-client/src/ts/api/shared/v1/common_pb.js'
import type { ReviewsAPIPromiseClient } from '@winestyle/api-client/src/ts/api/reviews/v1/reviews_api_grpc_web_pb'
import type { Review } from '../models/Review'
import type { ProductReviewsMetadata } from '../models/Metadata'

type BestReviewsResponse = [Review[] | undefined, CursorPagination | undefined, number | undefined]
type ProductReviewsResponse = [Review[] | undefined, CursorPagination | undefined]

export type ReviewsAPI = {
  getBestReviews: (paginator?: { size: number, cursor: string }) => Promise<BestReviewsResponse>
  getProductReviews: (id: number, paginator: { size: number, cursor: string }) => Promise<ProductReviewsResponse>
  getReviewsMetadata: (code: string) => Promise<ProductReviewsMetadata>
  sendReview: (
    productId: number,
    rating: string,
    comment: string,
    isBuyAgain: boolean) => ReturnType<ReviewsAPIPromiseClient['sendReview']>
}

export const reviewsAPI = (reviewsAPIClient: ReviewsAPIPromiseClient): ReviewsAPI => {
  async function getBestReviews (paginator: { size: number, cursor: string } = { size: 10, cursor: '0' }) {
    const request: GetBestReviewsRequest = new GetBestReviewsRequest()
    const paginatorReq = new CursorPaginator()

    paginatorReq.setSize(paginator.size)
    paginatorReq.setCursor(paginator.cursor)

    request.setPaginator(paginatorReq)

    const res = await reviewsAPIClient.getBestReviews(request)

    const reviewsList = res?.getData?.()?.getReviewsList?.()?.map?.(p => p.toObject())
    const pagination = res?.getData?.()?.getPagination?.()?.toObject?.()
    const count = res?.getData?.()?.getReviewCount?.()

    return [reviewsList, pagination, count] as BestReviewsResponse
  }

  async function getProductReviews (id: number, paginator: { size: number, cursor: string }) {
    const request: GetReviewsForProductRequest = new GetReviewsForProductRequest()
    const paginatorReq = new CursorPaginator()

    paginatorReq.setSize(paginator.size)
    paginatorReq.setCursor(paginator.cursor)

    request.setProductId(id)
    request.setPaginator(paginatorReq)

    const res = await reviewsAPIClient.getReviewsForProduct(request)

    const reviewsList = res?.getData?.()?.getReviewsList?.()?.map?.(p => p.toObject())
    const pagination = res?.getData?.()?.getPagination?.()

    return [reviewsList, pagination] as ProductReviewsResponse
  }

  async function getReviewsMetadata (code: string) {
    const request: GetMetadataRequest = new GetMetadataRequest()
    request.setProductCode(code)

    const res = await reviewsAPIClient.getMetadata(request)

    return res?.getData?.()?.toObject?.() as ProductReviewsMetadata
  }

  const sendReview = async (
    productId: number,
    rating: string,
    comment: string,
    isBuyAgain: boolean
  ) => {
    const request: SendReviewRequest = new SendReviewRequest()
    request.setProductId(productId)
    request.setComment(comment)
    request.setRating(rating)
    request.setWouldBuyItAgainMark(isBuyAgain)

    return await reviewsAPIClient.sendReview(request)
  }

  return { getBestReviews, getProductReviews, getReviewsMetadata, sendReview }
}
