import {
  AddProductToMyCartRequest,
  AddProductToMyCartResponse,
  DeselectItemsInMyCartRequest,
  DeselectItemsInMyCartResponse,
  EmptyMyCartRequest,
  EmptyMyCartResponse,
  GetMyCartRequest,
  GetMyCartResponse,
  GetMyCartBasicParametersRequest,
  GetMyCartBasicParametersResponse,
  RemoveItemsFromMyCartRequest,
  RemoveItemsFromMyCartResponse,
  SelectItemsInMyCartRequest,
  SelectItemsInMyCartResponse,
  SetItemQuantityInMyCartRequest,
  SetItemQuantityInMyCartResponse
} from '@winestyle/api-client/src/ts/api/sales/v1/cart_api_pb'

import type { CartAPIPromiseClient } from '@winestyle/api-client/src/ts/api/sales/v1/cart_api_grpc_web_pb'
import type { CartItem, Totals, Money } from '../models/Cart'
import type { ReqTypeWithResponseCases } from '@/modules/nuxt-api/models/type-helpers/reqTypeWithResponseCases'

type CartResponseType = AddProductToMyCartResponse
  | DeselectItemsInMyCartResponse
  | EmptyMyCartResponse
  | GetMyCartResponse
  | RemoveItemsFromMyCartResponse
  | SelectItemsInMyCartResponse
  | SetItemQuantityInMyCartResponse
type CartReturnType = [ CartItem[], Totals | undefined ]

const getCartResponseOptions = (response: CartResponseType): CartReturnType => {
  const cart = response.getData()?.toObject().cart
  const cartProducts = cart?.itemsList ?? []
  const cartTotals = cart?.totals

  return [cartProducts, cartTotals]
}

export type CartAPI = {
  getMyCart: () => Promise<CartReturnType>
  getMyCartShort: () => Promise<[[number, number][], Money | undefined]>
  addProductToMyCart: (productId: number, quantity: number, crossSellRuleId?: number) => Promise<CartReturnType>
  removeItemsFromMyCart: (productIds: number[]) => Promise<CartReturnType>
  setItemQuantityInMyCart: (productId: number, quantity: number) => Promise<CartReturnType>
  emptyMyCart: () => Promise<CartReturnType>
  selectItemsInMyCart: (productIds: number[]) => Promise<CartReturnType>
  deselectItemsInMyCart: (productIds: number[]) => Promise<CartReturnType>
}

export const cartAPI = (cartAPIClient: CartAPIPromiseClient): CartAPI => {
  const getMyCart = async (): Promise<CartReturnType> => {
    const request: ReqTypeWithResponseCases<GetMyCartRequest> = new GetMyCartRequest()
    request.responseCases = GetMyCartResponse.ResponseCase
    const response = await cartAPIClient.getMyCart(request)

    return getCartResponseOptions(response)
  }

  const getMyCartShort = async (): Promise<[[number, number][], Money | undefined]> => {
    const request: ReqTypeWithResponseCases<GetMyCartBasicParametersRequest> = new GetMyCartBasicParametersRequest()
    request.responseCases = GetMyCartBasicParametersResponse.ResponseCase
    const response = await cartAPIClient.getMyCartBasicParameters(request)

    const productsQuantity = response.getData()?.toObject?.()?.productsQuantityMap
    const total = response.getData()?.toObject?.()?.total

    return [productsQuantity ?? [], total]
  }

  const addProductToMyCart = async (
    productId: number,
    quantity: number,
    crossSellRuleId?: number
  ): Promise<CartReturnType> => {
    const request: ReqTypeWithResponseCases<AddProductToMyCartRequest> = new AddProductToMyCartRequest()
    request.responseCases = AddProductToMyCartResponse.ResponseCase

    request.setProductId(productId)
    request.setQuantity(quantity)
    crossSellRuleId && request.setCrossSellRuleId(crossSellRuleId)

    const response = await cartAPIClient.addProductToMyCart(request)

    return getCartResponseOptions(response)
  }

  const removeItemsFromMyCart = async (productIds: number[]): Promise<CartReturnType> => {
    const request: ReqTypeWithResponseCases<RemoveItemsFromMyCartRequest> = new RemoveItemsFromMyCartRequest()

    request.responseCases = RemoveItemsFromMyCartResponse.ResponseCase
    request.setItemIdsList(productIds)

    const response = await cartAPIClient.removeItemsFromMyCart(request)

    return getCartResponseOptions(response)
  }

  const setItemQuantityInMyCart = async (productId: number, quantity: number): Promise<CartReturnType> => {
    const request: ReqTypeWithResponseCases<SetItemQuantityInMyCartRequest> = new SetItemQuantityInMyCartRequest()

    request.responseCases = SetItemQuantityInMyCartResponse.ResponseCase
    request.setItemId(productId)
    request.setQuantity(quantity)

    const response = await cartAPIClient.setItemQuantityInMyCart(request)

    return getCartResponseOptions(response)
  }

  const emptyMyCart = async (): Promise<CartReturnType> => {
    const request: ReqTypeWithResponseCases<EmptyMyCartRequest> = new EmptyMyCartRequest()
    request.responseCases = EmptyMyCartResponse.ResponseCase
    const response = await cartAPIClient.emptyMyCart(request)

    return getCartResponseOptions(response)
  }

  const selectItemsInMyCart = async (productIds: number[]): Promise<CartReturnType> => {
    const request: ReqTypeWithResponseCases<SelectItemsInMyCartRequest> = new SelectItemsInMyCartRequest()

    request.responseCases = SelectItemsInMyCartResponse.ResponseCase
    request.setItemIdsList(productIds)

    const response = await cartAPIClient.selectItemsInMyCart(request)

    return getCartResponseOptions(response)
  }

  const deselectItemsInMyCart = async (productIds: number[]): Promise<CartReturnType> => {
    const request: ReqTypeWithResponseCases<DeselectItemsInMyCartRequest> = new DeselectItemsInMyCartRequest()

    request.responseCases = DeselectItemsInMyCartResponse.ResponseCase
    request.setItemIdsList(productIds)

    const response = await cartAPIClient.deselectItemsInMyCart(request)

    return getCartResponseOptions(response)
  }

  return {
    getMyCart,
    getMyCartShort,
    addProductToMyCart,
    removeItemsFromMyCart,
    setItemQuantityInMyCart,
    emptyMyCart,
    selectItemsInMyCart,
    deselectItemsInMyCart
  }
}
