import {
  GetShopsRequest,
  GetShopRequest,
  GetInfoForFSRARRequest,
  GetShopDrivingDirectionsRequest,
  GetShopResponse,
  GetMetadataRequest,
  GetMetadataResponse,
  GetShopDrivingDirectionsResponse
} from '@winestyle/api-client/src/ts/api/shop/v1/shop_api_pb.js'

import type { ShopAPIPromiseClient } from '@winestyle/api-client/src/ts/api/shop/v1/shop_api_grpc_web_pb.js'
import type { Shop, ShopDrivingDirection } from '../models/Shop'
import type { ShopMetadata } from '../models/Metadata'
import type { ReqTypeWithCustomAttrs } from '../models/type-helpers/reqTypeWithClientId'
import type { ReqTypeWithResponseCases } from '../models/type-helpers/reqTypeWithResponseCases'

export type ShopAPI = {
  getShops: (overrideRegion?: boolean) => Promise<Shop[] | undefined>
  getShop: (shopCode?: string) => Promise<Shop | undefined>
  getShopMetadata: (shopCode: string) => Promise<ShopMetadata | undefined>
  getInfoForFSRAR: (shopCodeList: string[]) => Promise<string[][] | undefined>
  getShopDrivingDirections: (shopCode: string) => Promise<ShopDrivingDirection[] | undefined>
}

export const shopAPI = (shopAPIClient: ShopAPIPromiseClient): ShopAPI => {
  async function getShops (overrideRegion = false) {
    const request: ReqTypeWithCustomAttrs<GetShopsRequest> = new GetShopsRequest()

    if (overrideRegion) {
      request.region = 1
    }

    const res = await shopAPIClient.getShops(request)

    return res?.getData?.()?.toObject?.()?.shopsList as Shop[]
  }

  async function getShop (shopCode = '') {
    const request: ReqTypeWithResponseCases<GetShopRequest> = new GetShopRequest()
    request.setShopCode(shopCode)
    request.responseCases = GetShopResponse.ResponseCase
    request.checkShopNotFound = true

    const res = await shopAPIClient.getShop(request)

    return res?.getData?.()?.toObject?.()?.shop as Shop
  }

  async function getShopDrivingDirections (shopCode = '') {
    const request: ReqTypeWithResponseCases<GetShopDrivingDirectionsRequest> = new GetShopDrivingDirectionsRequest()
    request.setShopCode(shopCode)
    request.responseCases = GetShopDrivingDirectionsResponse.ResponseCase
    request.checkShopNotFound = true

    const res = await shopAPIClient.getShopDrivingDirections(request)

    return res?.getData?.()?.toObject?.()?.drivingDirectionsList
  }

  async function getShopMetadata (shopCode: string = '') {
    const request: ReqTypeWithResponseCases<GetMetadataRequest> = new GetMetadataRequest()
    request.setShopCode(shopCode)
    request.responseCases = GetMetadataResponse.ResponseCase
    request.checkShopNotFound = true

    const res = await shopAPIClient.getMetadata(request)

    return res?.getData?.()?.toObject?.()
  }

  async function getInfoForFSRAR (shopCodeList: string[]) {
    const request: GetInfoForFSRARRequest = new GetInfoForFSRARRequest()
    request.setShopCodesList(shopCodeList)

    const res = await shopAPIClient.getInfoForFSRAR(request)

    return res?.getData?.()?.toObject?.()?.fsrarInfoMap
  }

  return { getShop, getShops, getShopMetadata, getInfoForFSRAR, getShopDrivingDirections }
}
