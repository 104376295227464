import {
  GetBannersForCatalogRequest,
  GetBannersForHomeRequest
} from '@winestyle/api-client/src/ts/api/marketing/v1/banner_api_pb'

import type { BannerAPIPromiseClient } from '@winestyle/api-client/src/ts/api/marketing/v1/banner_api_grpc_web_pb'
import type { ReqTypeWithResponseCases } from '../models/type-helpers/reqTypeWithResponseCases'

export const bannerAPI = (bannerAPIClient: BannerAPIPromiseClient) => {
  async function getBannersForHome () {
    const request: ReqTypeWithResponseCases<GetBannersForHomeRequest> = new GetBannersForHomeRequest()
    const res = await bannerAPIClient.getBannersForHome(request)

    return res?.getData?.()?.toObject?.()?.bannerCollectionsList || []
  }

  async function getBannersForCatalog (section: string) {
    const request: ReqTypeWithResponseCases<GetBannersForCatalogRequest> = new GetBannersForCatalogRequest()

    request.setSection(section)

    const res = await bannerAPIClient.getBannersForCatalog(request)
    return res?.getData?.()?.toObject?.()?.bannerCollectionsList || []
  }

  return { getBannersForHome, getBannersForCatalog }
}
