import {
  GetProfileInformationRequest,
  GetProfileInformationResponse,
  ChangePasswordRequest,
  ChangePasswordResponse
} from '@winestyle/api-client/src/ts/api/customer/v1/customer_profile_api_pb'

import type { CustomerProfileAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/customer_profile_api_grpc_web_pb'
import type { ReqTypeWithResponseCases } from '@/modules/nuxt-api/models/type-helpers/reqTypeWithResponseCases'

export const customerProfileApi = (customerProfileAPIClient: CustomerProfileAPIPromiseClient) => {
  async function getProfileInformation () {
    const request: ReqTypeWithResponseCases<GetProfileInformationRequest> = new GetProfileInformationRequest()

    request.responseCases = GetProfileInformationResponse.ResponseCase
    // captchaCode && request.setCaptchaCode(captchaCode)

    const res = await customerProfileAPIClient.getProfileInformation(request)
    return res.getData()?.toObject() || {}
  }

  async function changePassword (newPassword: string) {
    const request: ReqTypeWithResponseCases<ChangePasswordRequest> = new ChangePasswordRequest()

    request.setPassword(newPassword)
    request.responseCases = ChangePasswordResponse.ResponseCase

    const res = await customerProfileAPIClient.changePassword(request)
    return res.toObject()
  }

  return {
    getProfileInformation,
    changePassword
  }
}
