import {
  AddProductToWishListRequest,
  AddProductToWishListResponse,
  GetProductIdsFromWishListRequest,
  GetProductIdsFromWishListResponse,
  GetWishListItemsRequest,
  GetWishListItemsResponse,
  ClearProductWishListRequest,
  ClearProductWishListResponse,
  RemoveProductFromWishListRequest,
  RemoveProductFromWishListResponse
} from '@winestyle/api-client/src/ts/api/customer/v1/wishlist_api_pb'

import type { WishListAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/wishlist_api_grpc_web_pb'
import type { WishListItem } from '../models/WishList'
import type { ReqTypeWithResponseCases } from '@/modules/nuxt-api/models/type-helpers/reqTypeWithResponseCases'

export type WishListAPI = {
  addProductToWishList: (id: number) => Promise<void>
  getProductIdsFromWishList: () => Promise<number[]>
  getProductFromWishList: () => Promise<WishListItem[]>
  clearProductWishList: () => Promise<void>
  removeProductFromWishList: (id: number) => Promise<void>
}

export const wishListAPI = (wishListAPIClient: WishListAPIPromiseClient): WishListAPI => {
  const addProductToWishList = async (id: number) => {
    const request: ReqTypeWithResponseCases<AddProductToWishListRequest> = new AddProductToWishListRequest()
    request.setProductId(id)
    request.responseCases = AddProductToWishListResponse.ResponseCase

    try {
      await wishListAPIClient.addProductToWishList(request)
    } catch (error: any) {
      if (error.caseCode == AddProductToWishListResponse.ResponseCase.VALIDATION_ERROR) {
        const validationError = error.response.getValidationError().toObject()

        throw new Error(validationError.fieldsMap[0][0], validationError.fieldsMap[0][1].errorsList[0].text)
      } else {
        throw error
      }
    }
  }

  const getProductIdsFromWishList = async () => {
    const request: ReqTypeWithResponseCases<GetProductIdsFromWishListRequest> = new GetProductIdsFromWishListRequest()
    request.responseCases = GetProductIdsFromWishListResponse.ResponseCase

    const response = await wishListAPIClient.getProductIdsFromWishList(request)

    return response.getData()?.toObject().productIdsList ?? []
  }

  const getProductFromWishList = async () => {
    const request: ReqTypeWithResponseCases<GetWishListItemsRequest> = new GetWishListItemsRequest()
    request.responseCases = GetWishListItemsResponse.ResponseCase

    const response = await wishListAPIClient.getWishListItems(request)

    return response.getData()?.toObject().wishlistItemsList ?? []
  }

  const clearProductWishList = async () => {
    const request: ReqTypeWithResponseCases<ClearProductWishListRequest> = new ClearProductWishListRequest()
    request.responseCases = ClearProductWishListResponse.ResponseCase

    await wishListAPIClient.clearProductWishList(request)
  }

  const removeProductFromWishList = async (id: number) => {
    const request: ReqTypeWithResponseCases<RemoveProductFromWishListRequest> = new RemoveProductFromWishListRequest()

    request.responseCases = RemoveProductFromWishListResponse.ResponseCase
    request.setProductId(id)

    await wishListAPIClient.removeProductFromWishList(request)
  }

  return {
    addProductToWishList,
    getProductIdsFromWishList,
    getProductFromWishList,
    clearProductWishList,
    removeProductFromWishList
  }
}
