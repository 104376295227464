import { GetSupportInfoRequest } from '@winestyle/api-client/src/ts/api/marketing/v1/marketing_api_pb'

import type { MarketingAPIPromiseClient } from '@winestyle/api-client/src/ts/api/marketing/v1/marketing_api_grpc_web_pb'
import type { Marketing } from '../models/Marketing'

export type MarketingAPI = {
  getSupportInfo: () => Promise<Marketing | undefined>
}

export const marketingAPI = (marketingAPIClient: MarketingAPIPromiseClient): MarketingAPI => {
  async function getSupportInfo () {
    const request: GetSupportInfoRequest = new GetSupportInfoRequest()
    const res = await marketingAPIClient.getSupportInfo(request)

    return res?.getData?.()?.toObject?.()
  }

  return { getSupportInfo }
}
