import { GetSuggestionsRequest } from '@winestyle/api-client/src/ts/api/search/v1/search_api_pb.js'

import type { SearchAPIPromiseClient } from '@winestyle/api-client/src/ts/api/search/v1/search_api_grpc_web_pb'
import type { Suggestion } from '../models/Suggestion'

export type SearchAPI = {
  getSuggestions: (query?: string, category?: string) => Promise<Suggestion[] | undefined>
}

export const searchAPI = (searchAPIClient: SearchAPIPromiseClient): SearchAPI => {
  async function getSuggestions (query = '', category = '') {
    const request: GetSuggestionsRequest = new GetSuggestionsRequest()
    request.setQuery(query)
    category && request.setCategory(category)

    const res = await searchAPIClient.getSuggestions(request)

    return res?.getData()?.getSuggestionsList?.()?.map?.(p => p.toObject())
  }

  return { getSuggestions }
}
