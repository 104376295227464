import { PagePaginator } from '@winestyle/api-client/src/ts/api/shared/v1/common_pb'
import {
  GetOrderDetailsRequest,
  GetOrderDetailsResponse,
  GetOrdersRequest,
  GetPurchasedItemsRequest,
  GetPurchasedItemsResponse
} from '@winestyle/api-client/src/ts/api/customer/v1/orders_api_pb'

import type { OrdersAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/orders_api_grpc_web_pb'
import type { ReqTypeWithResponseCases } from '@/modules/nuxt-api/models/type-helpers/reqTypeWithResponseCases'

export const ordersApi = (ordersAPIClient: OrdersAPIPromiseClient) => {
  async function getOrders (paginator: { page: number, perPage: number }, only_current_orders = false) {
    const request: ReqTypeWithResponseCases<GetOrdersRequest> = new GetOrdersRequest()
    const paginatorReq = new PagePaginator()

    paginatorReq.setPage(paginator.page)
    paginatorReq.setItemsPerPage(paginator.perPage)

    request.setPaginator(paginatorReq)
    request.setOnlyCurrentOrder(only_current_orders)
    request.setImageItemsLimit(5)
    // captchaCode && request.setCaptchaCode(captchaCode)

    const res = await ordersAPIClient.getOrders(request)
    return res.getData()?.toObject()
  }

  async function getOrderDetails (id: number) {
    const request: ReqTypeWithResponseCases<GetOrderDetailsRequest> = new GetOrderDetailsRequest()
    request.setOrderId(id)
    request.responseCases = GetOrderDetailsResponse.ResponseCase
    // captchaCode && request.setCaptchaCode(captchaCode)

    const res = await ordersAPIClient.getOrderDetails(request)
    return res.getData()?.toObject()
  }

  async function getPurchasedItems () {
    const request: ReqTypeWithResponseCases<GetPurchasedItemsRequest> = new GetPurchasedItemsRequest()
    request.responseCases = GetPurchasedItemsResponse.ResponseCase

    const res = await ordersAPIClient.getPurchasedItems(request)
    return res.getData()?.toObject().purchasedItemsList ?? []
  }

  return {
    getOrders,
    getOrderDetails,
    getPurchasedItems
  }
}
