import {
  GetCategoryBreadcrumbsRequest,
  GetCategoryBreadcrumbsResponse,
  GetFiltersRequest,
  GetFiltersResponse,
  GetPopularSectionsRequest,
  GetPopularSectionsResponse,
  GetInterestingSectionsRequest,
  GetInterestingSectionsResponse,
  GetRatingDescriptionRequest,
  GetNavigationMenuRequest,
  GetCategoriesRequest,
  GetAllSortingListRequest,
  GetSiteMapRequest,
  GetMoreItemsForSiteMapRequest,
  GetFooterInterestingSectionsRequest,
  GetSectionByContextRequest,
  GetProductsByIdRequest
} from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_api_pb.js'
import { SectionPath } from '@winestyle/api-client/src/ts/api/catalog/v1/common_pb.js'
import { isArray, isNumber, isObject, isBoolean } from '@/utils/check-runtime-types'

import type { CatalogAPIPromiseClient } from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_api_grpc_web_pb.js'
import type { FilterGroup } from '../models/Filter'
import type { ReqTypeWithResponseCases } from '../models/type-helpers/reqTypeWithResponseCases'

export type GetFiltersOptions = {
  limit?: number
  filterCodes?: string[]
  forSEO?: boolean
}

export const catalogAPI = (catalogAPIClient: CatalogAPIPromiseClient) => {
  async function getProductsById (ids: Array<number>) {
    const request: ReqTypeWithResponseCases<GetProductsByIdRequest> = new GetProductsByIdRequest()
    request.setProductIdsList(ids)
    const res = await catalogAPIClient.getProductsById(request)
    return res.getData()?.toObject().productsList ?? []
  }
  async function getBreadcrumbs (path: string) {
    const request: ReqTypeWithResponseCases<GetCategoryBreadcrumbsRequest> = new GetCategoryBreadcrumbsRequest()
    const section = new SectionPath()

    section.setPath(path)
    request.setSection(section)
    request.responseCases = GetCategoryBreadcrumbsResponse.ResponseCase
    request.checkRedirect = true

    const res = await catalogAPIClient.getCategoryBreadcrumbs(request)

    return res.getData()?.getBreadcrumbsList().map(p => p.toObject()) ?? []
  }

  async function getFilters (path: string, options?: GetFiltersOptions, query = '') {
    const request: ReqTypeWithResponseCases<GetFiltersRequest> = new GetFiltersRequest()

    request.setQuery(query)
    request.responseCases = GetFiltersResponse.ResponseCase
    request.checkRedirect = true

    if (path.length) {
      const section = new SectionPath()
      section.setPath(path)
      request.setSection(section)
    }

    if (isObject(options) && isNumber(options.limit)) { request.setFilterItemsLimit(options.limit) }
    if (isObject(options) && isArray(options.filterCodes)) { request.setSelectionByFilterCodesList(options.filterCodes) }
    if (isObject(options) && isBoolean(options.forSEO)) { request.setIncludeSeoData(options.forSEO) }

    const res = await catalogAPIClient.getFilters(request)

    const filters = res?.getData()?.getFiltersList?.()?.map?.(f => f.toObject())
    let seoFilters: FilterGroup[] | undefined

    if (options?.forSEO ?? false) {
      seoFilters = res?.getData()?.getSeoFiltersList?.()?.map?.(f => f.toObject())
    }

    return [filters as FilterGroup[] ?? [], seoFilters ?? []]
  }

  async function getSectionByContext (contextList: string[]) {
    const request: ReqTypeWithResponseCases<GetSectionByContextRequest> = new GetSectionByContextRequest()
    request.setContextList(contextList)

    const res = await catalogAPIClient.getSectionByContext(request)

    return res.getData()?.toObject()?.section?.path || ''
  }

  async function getPopularSections (path: string) {
    const request: ReqTypeWithResponseCases<GetPopularSectionsRequest> = new GetPopularSectionsRequest()
    const section = new SectionPath()

    section.setPath(path)
    request.setSection(section)
    request.responseCases = GetPopularSectionsResponse.ResponseCase
    request.checkRedirect = true

    const res = await catalogAPIClient.getPopularSections(request)

    return res.getData()?.toObject()?.popularSectionsList || []
  }

  async function getInterestingSection (path: string) {
    const request: ReqTypeWithResponseCases<GetInterestingSectionsRequest> = new GetInterestingSectionsRequest()
    const section = new SectionPath()

    section.setPath(path)
    request.setSection(section)
    request.responseCases = GetInterestingSectionsResponse.ResponseCase
    request.checkRedirect = true

    const res = await catalogAPIClient.getInterestingSections(request)

    return res.getData()?.getInterestingSectionsList().map(p => p.toObject())
  }

  async function getRatingDescription (code: string) {
    const request: GetRatingDescriptionRequest = new GetRatingDescriptionRequest()
    request.setRatingCode(code)

    const res = await catalogAPIClient.getRatingDescription(request)

    return res.getData?.()?.getRatingDescriptionHtml?.()
  }

  async function getCategories () {
    const request: GetCategoriesRequest = new GetCategoriesRequest()
    const res = await catalogAPIClient.getCategories(request)

    return res.getData()?.getCategoriesList().map(p => p.toObject())
  }

  async function getNavigationMenu () {
    const request: GetNavigationMenuRequest = new GetNavigationMenuRequest()
    const res = await catalogAPIClient.getNavigationMenu(request)

    return res.getData()?.toObject()
  }

  async function getSortingList () {
    const request: GetAllSortingListRequest = new GetAllSortingListRequest()
    const res = await catalogAPIClient.getAllSortingList(request)

    return res.getData()?.toObject()?.sortingListMap
  }

  async function getSiteMap () {
    const request: GetSiteMapRequest = new GetSiteMapRequest()
    const res = await catalogAPIClient.getSiteMap(request)

    return res.getData()?.toObject()?.navigationsList
  }

  async function getMoreItemsForSiteMap (codeGroup: string, codeBlock: string) {
    const request: GetMoreItemsForSiteMapRequest = new GetMoreItemsForSiteMapRequest()
    request.setBlockCode(codeBlock)
    request.setGroupCode(codeGroup)

    const res = await catalogAPIClient.getMoreItemsForSiteMap(request)

    return res.getData()?.toObject()
  }

  async function getFooterInterestingSections () {
    const request: GetFooterInterestingSectionsRequest = new GetFooterInterestingSectionsRequest()
    const res = await catalogAPIClient.getFooterInterestingSections(request)

    return res.getData()?.toObject()
  }

  return {
    getProductsById,
    getSectionByContext,
    getBreadcrumbs,
    getFilters,
    getPopularSections,
    getInterestingSection,
    getRatingDescription,
    getCategories,
    getNavigationMenu,
    getSortingList,
    getSiteMap,
    getMoreItemsForSiteMap,
    getFooterInterestingSections
  }
}
