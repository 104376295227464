import {
  GetCategoryMetadataRequest,
  GetCategoryMetadataResponse,
  GetSearchMetadataRequest,
  GetSearchMetadataResponse,
  GetHomeMetadataRequest
} from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_api_pb.js'
import { SectionPath } from '@winestyle/api-client/src/ts/api/catalog/v1/common_pb.js'

import type { CatalogAPIPromiseClient } from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_api_grpc_web_pb'
import type { SearchMetadata } from '../models/SearchMetadata'
import type { HomeMetadata } from '../models/Metadata'
import type { ReqTypeWithResponseCases } from '../models/type-helpers/reqTypeWithResponseCases'

export type MetaAPI = {
  getMetadata: (path: string, page?: number) => Promise<GetCategoryMetadataResponse | null>
  getHomeMetadata: () => Promise<HomeMetadata | undefined>
  getSearchMetadata: (path: string, query: string) => Promise<SearchMetadata | undefined>
}

export const metaAPI = (catalogAPIClient: CatalogAPIPromiseClient): MetaAPI => {
  async function getMetadata (path: string, page: number = 1) {
    const request: ReqTypeWithResponseCases<GetCategoryMetadataRequest> = new GetCategoryMetadataRequest()
    const section = new SectionPath()

    section.setPath(path)
    request.setSection(section)
    page > 1 && request.setPage(page)

    request.responseCases = GetCategoryMetadataResponse.ResponseCase
    request.checkRedirect = true

    const res = await catalogAPIClient.getCategoryMetadata(request)
    return res
  }

  async function getHomeMetadata () {
    const request: GetHomeMetadataRequest = new GetHomeMetadataRequest()

    const res = await catalogAPIClient.getHomeMetadata(request)
    return res?.getData?.()?.toObject?.()
  }

  async function getSearchMetadata (path: string, query = '') {
    const request: ReqTypeWithResponseCases<GetSearchMetadataRequest> = new GetSearchMetadataRequest()

    if (path.length) {
      const section = new SectionPath()
      section.setPath(path)
      request.setSection(section)
    }

    request.setQuery(query)
    request.responseCases = GetSearchMetadataResponse.ResponseCase

    const res = await catalogAPIClient.getSearchMetadata(request)
    return res?.getData?.()?.toObject?.()
  }

  return { getMetadata, getSearchMetadata, getHomeMetadata }
}
