import {
  GetHomeProductCollectionsRequest,
  GetBestsellerProductCollectionsRequest,
  GetProductCollectionsForProductPageRequest,
  GetCartProductCollectionsRequest,
  GetProductCollectionsRequest
} from '@winestyle/api-client/src/ts/api/catalog/v1/product_collections_api_pb.js'
import { ProductCollectionParameters } from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_pb.js'
import type { ProductCollectionAPIPromiseClient } from '@winestyle/api-client/src/ts/api/catalog/v1/product_collections_api_grpc_web_pb'
import type { ProductCollection } from '../models/ProductCollection'
import type { ProductCollections } from '../models/ProductCollections'

export type ProductCollectionAPI = {
  getBestsellersCollection: () => Promise<ProductCollection[] | undefined>
  getHomeProductCollectionsRequest: () => Promise<ProductCollection[] | undefined>
  getBestsellerProductCollectionsRequest: () => Promise<ProductCollection[] | undefined>
  getProductCollectionsForProductPageRequest: (productCode?: string) => Promise<ProductCollection[] | undefined>
  getCartProductCollectionsRequest: () => Promise<ProductCollection[] | undefined>
  getProductCollectionsRequest: (collection: ProductCollectionParameters.AsObject[]) => Promise<ProductCollections[] | undefined>
}

export const productCollectionAPI = (productCollectionAPIClient: ProductCollectionAPIPromiseClient): ProductCollectionAPI => {
  async function getBestsellersCollection () {
    const request: GetBestsellerProductCollectionsRequest = new GetBestsellerProductCollectionsRequest()
    const res = await productCollectionAPIClient.getBestsellerProductCollections(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }

  async function getHomeProductCollectionsRequest () {
    const request: GetHomeProductCollectionsRequest = new GetHomeProductCollectionsRequest()
    const res = await productCollectionAPIClient.getHomeProductCollections(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }

  async function getBestsellerProductCollectionsRequest () {
    const request: GetBestsellerProductCollectionsRequest = new GetBestsellerProductCollectionsRequest()
    const res = await productCollectionAPIClient.getBestsellerProductCollections(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }

  async function getProductCollectionsForProductPageRequest (productCode?: string) {
    const request: GetProductCollectionsForProductPageRequest = new GetProductCollectionsForProductPageRequest()

    if (productCode) { request.setProductCode(productCode) }

    const res = await productCollectionAPIClient.getProductCollectionsForProductPage(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }
  async function getCartProductCollectionsRequest () {
    const request: GetCartProductCollectionsRequest = new GetCartProductCollectionsRequest()
    const res = await productCollectionAPIClient.getCartProductCollections(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }

  async function getProductCollectionsRequest (collectionsList: ProductCollectionParameters.AsObject[]) {
    const request: GetProductCollectionsRequest = new GetProductCollectionsRequest()
    const list: ProductCollectionParameters[] = []

    collectionsList.forEach((p) => {
      const item = new ProductCollectionParameters()
      if (p.limit) { item.setLimit(p.limit) }
      item.setCode(p.code)
      list.push(item)
    })

    request.setProductCollectionsParametersList(list)
    const res = await productCollectionAPIClient.getProductCollections(request)
    return res?.getData()?.toObject()?.productCollectionsList
  }

  return {
    getBestsellersCollection,
    getHomeProductCollectionsRequest,
    getBestsellerProductCollectionsRequest,
    getProductCollectionsForProductPageRequest,
    getCartProductCollectionsRequest,
    getProductCollectionsRequest
  }
}
